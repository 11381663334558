import { mdiThumbUpOutline, mdiCommentProcessingOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useState } from 'react';
import { FormControl, InputGroup, Button, Card } from 'react-bootstrap';
import { likeUnlikePost } from '../network/ApiManager';
import dayjs from 'dayjs';
import CommentsModal from './CommentsModal';

const Post = ({ postData, username }) => {

    const [post, setPost] = useState(postData);
    const [showCommentsModal, setShowCommentsModal] = useState(false);

    const _likeUnlikePost = () => {
        likeUnlikePost(username, post.id).then(response => {
            if (response === undefined) {
                window.alert("Error liking post");
            } else {
                setPost(response.result);
            }
        })
    }

    const getYoutubeIdFromUrl = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match && match[7].length == 11) ? match[7] : false;
    }

    const showMedia = (post) => {
        if (post.media !== undefined && post.media !== null && post.media !== ''
            && post.mediaType !== '' && post.mediaType !== undefined && post.mediaType !== null) {
            switch (post.mediaType) {
                case 'youtube':
                    const youtubeId = getYoutubeIdFromUrl(post.media);
                    return <iframe
                        width="100%"
                        height="300"
                        src={`https://www.youtube.com/embed/${youtubeId}`}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen />;
                case 'image':
                    return <img src={post.media} width="100%" />;
                default:
                    return null;
            }
        } else {
            return null;
        }
    }

    const postDate = dayjs(post.timestamp).format('DD MMM YYYY, hh:mm A');

    return <Card className="mb-3">
        {showMedia(post)}
        <Card.Body className="m-2">
            <h5>{post.title}</h5>
            <Card.Text>
                <small><p className="small m-0 p-0 text-secondary">{'Posted by '} <b>{post.username}</b> {' on ' + postDate}</p></small>
                {post.content}
            </Card.Text>
            <div>
                <div style={{ float: 'left' }}>
                    <Icon path={mdiThumbUpOutline}
                        title="Like"
                        size={1}
                        onClick={() => _likeUnlikePost()}
                        color={post.likes.includes(username) ? "blue" : "gray"} />

                    {` ${post.likesCount > 0 ? post.likesCount : ''}`}
                </div>

                <div onClick={() => setShowCommentsModal(true)} style={{ float: 'left' }}>
                    <Icon className="ms-3" path={mdiCommentProcessingOutline}
                        title="Comments"
                        size={1}
                        color="gray" />
                    {` ${post.commentsCount > 0 ? post.commentsCount : 'No'} comments`}
                </div>

            </div>
        </Card.Body>

        <CommentsModal
            show={showCommentsModal}
            setShow={setShowCommentsModal}
            username={username}
            postData={post}
            updatePost={setPost} />

    </Card>
}

export default Post;