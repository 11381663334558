import { useEffect, useState } from "react";
import Post from './Post'
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Posts = ({posts, username}) => {
    return <Row> {
        posts.map(post => {
            return <Col sm={12} md={{span:6, offset: 3}} key={post.id}>
                <Post postData={post} username={username}/>
            </Col>
        })
    }</Row>
}

export default Posts;