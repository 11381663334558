const BASE_URL = 'https://socio.rtficial.workers.dev'

const ENDPOINTS = {
    POSTS: '/posts',
    LIKEUNLIKE: '/posts/likeUnlike',
    COMMENT: '/posts/comment'
}

export const getPosts = async () => {
    return await fetch(BASE_URL + ENDPOINTS.POSTS)
        .then(response => response.json())
        .catch(error => {
            console.log(error);
            return undefined;
        });
}

export const createPost = async (data) => {
    return await fetch(BASE_URL + ENDPOINTS.POSTS, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .catch(error => {
            console.log(error.message);
            return undefined;
        });
}

export const likeUnlikePost = async (username, postId) => {
    const data = {
        username: username,
        postId: postId
    }
    return await fetch(BASE_URL + ENDPOINTS.LIKEUNLIKE, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .catch(error => {
            console.log(error.message);
            return undefined;
        });
}

export const commentOnPost = async (username, postId, comment) => {
    const data = {
        username: username,
        postId: postId,
        comment: comment
    }
    return await fetch(BASE_URL + ENDPOINTS.COMMENT, {
        method: 'PUT',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .catch(error => {
            console.log(error.message);
            return undefined;
        });
}
