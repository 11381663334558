import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

const Topbar = ({setShowNewPost}) => {
    return <Navbar bg="light" variant="light" fixed="top" className="pe-3">
        <Navbar.Brand>
            <h4 className="logo text-primary">Socio</h4>
        </Navbar.Brand>
        <Nav className="ms-auto">
            <Button variant="primary" onClick={() => setShowNewPost(true)}>New Post</Button>
        </Nav>
    </Navbar>
}

export default Topbar;