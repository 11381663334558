import { useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import NewPostModal from './components/NewPostModal';
import Topbar from './components/Topbar';
import Posts from './components/Posts';
import UsernameModal from './components/UsernameModal';
import './App.css';
import { getPosts } from './network/ApiManager';

function App() {
  const [refreshPosts, setRefreshPosts] = useState(false);
  const [username, setUsername] = useState(null);
  const [showUsernameModal, setShowUsernameModal] = useState(false);
  const [showNewPost, setShowNewPost] = useState(false);

  const [posts, setPosts] = useState([]);

  const _getPosts = () => {
    getPosts().then(data => {
      const postsResponse = data ? data.result : [];
      postsResponse.sort((a, b) => parseFloat(b.timestamp) - parseFloat(a.timestamp));
      setPosts([]);
      setPosts(postsResponse);
      setRefreshPosts(false);
    });
  }

  useEffect(() => {
    setShowUsernameModal(username === null);
    _getPosts();
  }, [])

  useEffect(() => {
    setShowUsernameModal(username === null);
  }, [username])

  useEffect(() => {
    if (refreshPosts) {
      _getPosts();
    }
  }, [refreshPosts])


  return (
    <div className="App">
      {
        username === null ?
          <div className="customModal">
            <UsernameModal
              show={showUsernameModal}
              setShow={setShowUsernameModal}
              setUsername={setUsername} />

          </div>
          :
          <div>
            <Topbar setShowNewPost={setShowNewPost} />
            <Container className="pt-4 pl-3 pr-3 pb-3 mt-5">
              <Posts posts={posts} username={username} />
              <div className="customModal">
                <NewPostModal
                  username={username}
                  refreshPosts={setRefreshPosts}
                  show={showNewPost}
                  setShow={setShowNewPost} />
              </div>
            </Container>
          </div>
      }
    </div>
  );
}

export default App;
