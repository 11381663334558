import { useState } from "react";
import { Modal, Button, InputGroup, FormControl, ListGroup } from "react-bootstrap"
import { commentOnPost } from "../network/ApiManager";
import dayjs from 'dayjs';

const CommentsModal = ({ show, setShow, username, postData, updatePost }) => {

    const [commentInput, setCommentInput] = useState('')

    const _commentOnPost = () => {
        if(commentInput.trim() !== '') {
            commentOnPost(username, postData.id, commentInput)
            .then(response => {
                if(response !== undefined) {
                    updatePost(response.result);
                } else {
                    window.alert('Failed to post comment');
                }
            })
        } else {
            window.alert('Type something to comment!');
        }
       
    }

    return <Modal
        show={show}
        onHide={setShow}
        keyboard={false}>
        <Modal.Header closeButton>
            <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            {
                postData.comments.length > 0 ?
                    <ListGroup variant="flush">
                        {
                            postData.comments?.map(comment => {
                                const commentDate = dayjs(comment.timestamp).format('DD MMM YYYY, hh:mm A');
                                return <ListGroup.Item key={postData.comments.indexOf(comment)}>
                                    <div>
                                        <small><small><p className="m-0 p-0"><b>{comment.username}</b>{` on ${commentDate} said:`}</p></small></small>
                                        <p className="m-0 p-0">{comment.comment}</p>
                                    </div>
                                </ListGroup.Item>
                            })
                        }
                    </ListGroup>
                    :
                    <p className="text-center">Be the first one to comment!</p>
            }
        </Modal.Body>
        <Modal.Footer>
            <InputGroup>
                <FormControl
                    placeholder="Enter your comment" onChange={e => setCommentInput(e.target.value)} />
                <Button variant="primary" onClick={() => _commentOnPost()}>Post</Button>
            </InputGroup>
        </Modal.Footer>
    </Modal>
}

export default CommentsModal;