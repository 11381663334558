import { useState } from "react";
import { Form, Modal, Button, InputGroup, FormControl } from "react-bootstrap"
import { createPost } from "../network/ApiManager";

const NewPostModal = ({ show, setShow, username, refreshPosts }) => {

    const blankPost = {
        title: '',
        username: username,
        content: '',
        mediaType: '',
        media: ''
    };

    const [postData, setPostData] = useState(blankPost);

    const modifyPostData = (key, val) => {
        let newData = postData;
        switch (key) {
            case 'title':
                newData = { ...postData, title: val }
                break;
            case 'content':
                newData = { ...postData, content: val }
                break;
            case 'mediaType':
                newData = { ...postData, mediaType: val }
                break;
            case 'media':
                newData = { ...postData, media: val }
                break;
        }
        setPostData(newData);
    };

    const createNewPost = () => {
        console.log(postData)
        if (postData.title === '' || postData.content === '') {
            window.alert('Missing post title or content');
        } else {
            createPost(postData).then(response => {
                if (response.code === 201) {
                    setPostData(blankPost);
                    refreshPosts(true);
                    setShow(false);
                } else {
                    window.alert('Failed to create post. Try again.')
                }
            })
        }
    };

    return <Modal
        show={show}
        onHide={setShow}>
        <Modal.Header closeButton>
            <Modal.Title>New Post</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Control
                className="mb-3 mt-3"
                type="text"
                placeholder="Give it a nice title"
                onChange={e => modifyPostData('title', e.target.value)} />
            <Form.Control
                as="textarea"
                rows={3}
                placeholder="What are you upto?"
                onChange={e => modifyPostData('content', e.target.value)} />
            <h6 className="mt-3">Add Media</h6>
            <InputGroup>
                <div style={{ width: '115px' }}>
                    <Form.Select onChange={e => modifyPostData('mediaType', e.target.value)}>
                        <option value="">Type</option>
                        <option value="image">Image</option>
                        <option value="youtube">YouTube</option>
                    </Form.Select>
                </div>
                <FormControl
                    placeholder="Enter media link here"
                    onChange={e => modifyPostData('media', e.target.value)} />
            </InputGroup>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => createNewPost()}>Create Post</Button>
        </Modal.Footer>
    </Modal>
}

export default NewPostModal;