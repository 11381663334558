import { useState } from "react";
import { Form, Modal, Button, Card } from "react-bootstrap"

const UsernameModal = ({ show, setShow, setUsername }) => {

    const [usernameInput, setUsernameInput] = useState('');

    const submitUsername = () => {
        if (usernameInput != '') {
            setUsername(usernameInput);
            setShow(false);
        } else {
            window.alert('Invalid Username');
        }
    }

    return <Modal
        show={show}
        onHide={setShow}
        backdrop="static"
        keyboard={false}>
        <Modal.Header>
            <Modal.Title>Login</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form.Control
                className="mb-3 mt-3"
                type="text"
                placeholder="Your Email Address"
                onChange={e => setUsernameInput(e.target.value)} />
        </Modal.Body>
        <Modal.Footer>
            <Button variant="primary" onClick={() => submitUsername()}>Login</Button>
        </Modal.Footer>
    </Modal>
}

export default UsernameModal;